<template>
  <div>
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  mounted() {}
};
</script>

<style lang="less">
@font-face {
  font-family: 'MiSans';
  src: url('./common/fonts/misans/MiSans-Normal.woff2') format('woff2');
  src: url('./common/fonts/misans/MiSans-Normal.woff') format('woff');
  src: url('./common/fonts/misans/MiSans-Normal.ttf') format('truetype');
  font-weight: 400;  /* 需与字体文件实际字重匹配 */
  font-style: italic;  /* 若字体包含斜体变体则需设置 */
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  min-width: 1260px;
  width: 100vw;
  height: 100vh;
  color: #000 !important;
  font-family: "MiSans","serif" !important;
}
ul {
  list-style: none;
}
.text-right {
  right: 15vw;
  left: unset !important;
}
.white {
  color: #fff;
}
.grey {
  background: #fbfbfb;
}
.warp{
  width: 100%;
}
.warp section{
  position: relative;
  width: 100%;
  overflow-x: hidden;
}
/* 底部说明 */
.explain {
  font-size: 0.9vw;
  width: 60%;
  margin: 5vw auto;
  list-style: disc;
  letter-spacing: 0.5px;
}
.explain li {
  margin-bottom: 0.38vw;
}
</style>
